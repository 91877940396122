


















import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import { GenericListAPI } from "@/apps/core/api/list";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "GenericSelect",
  props: {
    apiPath: { type: String, required: true },
    isDate: { type: Boolean, default: false },
    params: Object,
    placeholder: String,
    required: { type: Boolean, default: true },
    value: { type: Object, default: null }, // uuid,
  },
  setup(props, { emit }) {
    const blank = { id: null, nama: null };
    const valueData = reactive(Object.assign({}, props.value ?? blank));
    const api = new GenericListAPI(props.apiPath);
    const options = reactive(api.options);
    let optionsMap = {} as Record<string, Record<string, string>>;
    const emitInput = () => {
      emit("input", valueData.id === null ? null : valueData);
    };
    const computedValue = computed({
      get(): string {
        const value = valueData?.id;
        return value;
      },
      set(value: string) {
        const adjValue = optionsMap[value] ?? blank;
        Object.assign(valueData, adjValue);
        emitInput();
      },
    });

    watch(
      () => props.value,
      (newValue) => {
        // Memonitor perubahan dari props, set valueData
        // dengan value baru agar render ulang component
        if (valueData !== newValue) {
          Object.assign(valueData, newValue);
        }
      }
    );

    const fetchOptions = async () => {
      const optionArr = await api.fetch();
      const sortedOptions = props.isDate? optionArr: optionArr.reverse();
      optionsMap = {};
      sortedOptions.forEach((option: Record<string, string>) => {
        if (props.isDate) option.nama = toUserDateFormat(option.nama);
        optionsMap[option.id] = option;
      });
    };
    fetchOptions();

    return {
      // Data
      options,

      // Computed,
      computedValue,

      // Method
    };
  },
});
